import React from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <span title="Show all options" />
    </components.DropdownIndicator>
  )
);

const SimpleSelector = (props) => {
  const {
    value,
    inputFieldName,
    options,
    onChange,
    customHeight,
    inModal
  } = props;

  const menuPortalTarget = inModal ? document.body : null;
  const controlStyle = {
    control: base => ({
      ...base, height: customHeight || 32, minHeight: customHeight || 32, maxHeight: 32
    })
  };

  const styles = inModal
    ? { ...controlStyle, menuPortal: base => ({ ...base, zIndex: 10001 }) }
    : controlStyle;

  return (
    <div className="user-select" style={{ width: '250px', marginLeft: '20px' }}>
      <Select
        value={value}
        name={inputFieldName}
        options={options}
        onChange={onChange}
        components={{ DropdownIndicator }}
        classNamePrefix="UserSelector"
        styles={styles}
        menuPortalTarget={menuPortalTarget}
      />
    </div>
  );
};

export default SimpleSelector;
