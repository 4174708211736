import React from 'react';
import HelpPoint from '../Helpers/HelpPoint';

const DataSchemaFormFields = (props) => {
  const {
    helpPoints,
    technicalQualityRule,
    dataSchemaName,
    dataSchemaColumnName,
    dataSchemaTableName
  } = props;

  return (
    <div style={{ marginBottom: '20px' }}>
      <div className="technical-quality-rule__column-placeholder">
        <div className="flex-box" >
          <h5 className="technical-quality-rule__column-drop-label">
            Drag and drop column from schema browser here or enter manually:
          </h5>
          <HelpPoint helpPoint={helpPoints.point5} />
        </div>
        <div>
          <h5>Schema: </h5>
          <span className="margin-right-15px">
            <HelpPoint helpPoint={helpPoints.point6} />
          </span>
          <input
            value={technicalQualityRule.schema_name}
            type="text"
            className="schema-name technical-info-field-to-check"
            data-allows-react-hotkeys="true"
            size={30}
            name={dataSchemaName}
          />
        </div>

        <div>
          <h5>Table/View: </h5>
          <span className="margin-right-15px">
            <HelpPoint helpPoint={helpPoints.point7} />
          </span>
          <input
            value={technicalQualityRule.table_name}
            type="text"
            className="table-name technical-info-field-to-check"
            data-allows-react-hotkeys="true"
            size={30}
            name={dataSchemaTableName}
          />
        </div>

        <div>
          <h5>Column: </h5>
          <span className="margin-right-15px">
            <HelpPoint helpPoint={helpPoints.point8} />
          </span>
          <input
            value={technicalQualityRule.column_name}
            type="text"
            className="column-name technical-info-field-to-checkk"
            data-allows-react-hotkeys="true"
            size={30}
            name={dataSchemaColumnName}
          />
        </div>
      </div>
    </div>
  );
};

export default DataSchemaFormFields;
