import React, { useState } from 'react';
import DataSystemSelector from '../DataSystems/DataSystemSelector';
import HelpPoint from '../Helpers/HelpPoint';
import DataSchemaFormFields from './DataSchemaFormFields';
import QueryLanguageForm from '../shared/QueryLanguageForm';

const TechnicalInformationFormFields = (props) => {
  const {
    erpSystem,
    erpSystems,
    fieldName,
    browseSchema,
    dataSystemHasSchema,
    technicalQualityRuleIndex,
    browseDataSystemLinkPath,
    hasEdsModule,
    helpPoints,
    hasQualityRuleComplexValidation,
    selectedLanguage,
    availableLanguages,
    scopeQueryInputFieldName,
    testQueryInputFieldName,
    researchQueryInputFieldName,
    scopeQuery,
    testQuery,
    researchQuery
  } = props;

  const convertLanguage = (value) => {
    const newValue = value || '';

    return {
      value: newValue,
      label: newValue
    };
  };

  const [language, setLanguage] = useState(convertLanguage(selectedLanguage));
  const languages = () => availableLanguages.map(lang => convertLanguage(lang));

  return (
    <div>
      <div className="flex-box_space-between" style={{ alignItems: 'center', marginBottom: '10px' }}>
        <div className="flex-box">
          <div className="flex-box_space-between margin-right-15px">
            <label>
              Data System:
            </label>
            <HelpPoint helpPoint={helpPoints.point4} />
          </div>
          <DataSystemSelector
            erpSystem={erpSystem}
            erpSystems={erpSystems}
            fieldName={fieldName}
            browseSchema={browseSchema}
            setLanguage={setLanguage}
            convertLanguage={convertLanguage}
          />
        </div>

        {
          hasEdsModule &&
          <div>
            <HelpPoint helpPoint={helpPoints.point3} />
            <a
              className={`data-model-schema data-model-schema-quality-rule pretty-button med ${dataSystemHasSchema ? '' : 'disabled'}`}
              title={dataSystemHasSchema ? '' : 'There are no data model objects to browse for the selected data system'}
              data-allows-react-hotkeys="true"
              data-browse-data-systems-modal-trigger="true"
              data-trigger-id={technicalQualityRuleIndex}
              href={browseDataSystemLinkPath}
            >
              Browse Data System
            </a>
          </div>
        }
      </div>

      {
        !hasQualityRuleComplexValidation &&
        <DataSchemaFormFields
          {...props}
        />
      }

      <QueryLanguageForm
        language={language}
        setLanguage={setLanguage}
        languages={languages()}
        formHeader="Scope Query"
        textAreaFieldName={scopeQueryInputFieldName}
        queryFieldValue={scopeQuery}
        headerHelpPoint={helpPoints.point1}
        {...props}
      />

      {

        <QueryLanguageForm
          language={language}
          setLanguage={setLanguage}
          languages={languages()}
          formHeader="Test Query"
          textAreaFieldName={testQueryInputFieldName}
          queryFieldValue={testQuery}
          headerHelpPoint={helpPoints.point9}
          additionalClassName="technical-quality-rule__test-query"
          {...props}
        />
      }

      {
        <QueryLanguageForm
          language={language}
          setLanguage={setLanguage}
          languages={languages()}
          formHeader="Research Query"
          textAreaFieldName={researchQueryInputFieldName}
          queryFieldValue={researchQuery}
          headerHelpPoint={helpPoints.point10}
          additionalClassName="technical-quality-rule__test-query"
          {...props}
        />
      }
    </div>
  );
};

export default TechnicalInformationFormFields;
