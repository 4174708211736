import React, { useState } from 'react';
import SimpleSelector from './SimpleSelector';
import CodeArea from './CodeArea';
import HelpPoint from '../Helpers/HelpPoint';

const QueryLanguageForm = (props) => {
  const {
    selectedLanguage,
    availableLanguages,
    selectorFieldName,
    textAreaFieldName,
    queryFieldValue,
    helpPoints,
    language,
    setLanguage,
    languages,
    formHeader,
    headerHelpPoint,
    additionalClassName
  } = props;

  const handleLanguageChange = option => setLanguage(option);

  return (
    <div className={additionalClassName ? additionalClassName : ''}>
      <div className="flex-box_space-between" style={{ marginBottom: '10px', alignItems: 'center' }}>
        <div>
          <label className="inline">
            {formHeader}
          </label>
          <HelpPoint helpPoint={headerHelpPoint} />
        </div>

        <div className="flex-box" style={{ alignItems: 'center' }}>
          <label className="inline">
            Query language
          </label>
          <HelpPoint helpPoint={helpPoints.point2} />
          <SimpleSelector
            selectedValue={selectedLanguage}
            availableOpions={availableLanguages}
            value={language}
            inputFieldName={selectorFieldName}
            options={languages}
            onChange={handleLanguageChange}
          />
        </div>
      </div>

      <CodeArea
        inputFieldName={textAreaFieldName}
        selectedLanguage={language}
        queryFieldValue={queryFieldValue}
      />
    </div>
  );
};

export default QueryLanguageForm;
