import OptimizedSelect from '../shared/OptimizedSelect';

class DataSystemSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedErpSystem: props.erpSystem ? {
        value: props.erpSystem.id,
        label: props.erpSystem.name,
        schemaExists: props.erpSystem.schemaExists,
        description: props.erpSystem.systemType,
        type: 'name',
        systemLanguage: props.erpSystem.systemLanguage
      } : null
    };
  }

  dataSystemsOptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style, valueArray }) => {
    const classNames = ['nameOption'];

    if (option.type === 'header') {
      classNames.push('nameHeader');

      return (
        <div
          className={classNames.join(' ')}
          key={key}
          style={style}
        >
          {option.label}
        </div>
      );
    }

    classNames.push('nameField');
    if (option === focusedOption) classNames.push('nameOptionFocused');
    if (valueArray.indexOf(option) >= 0) classNames.push('nameOptionSelected');

    return (
      <div
        className={classNames.join(' ')}
        key={key}
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        style={style}
      >
        {option.label}
      </div>
    );
  };

  updateErpSystem(selectedErpSystem) {
    this.setState({ selectedErpSystem }, () => {
      this.erpSystemCallbacks(selectedErpSystem);
    });
    
    if (this.props.setLanguage && this.props.convertLanguage) {
      this.props.setLanguage(this.props.convertLanguage(selectedErpSystem.systemLanguage))
    }
  }

  erpSystemCallbacks = (selectedErpSystem) => {
    if (this.props.techDefinition) {
      // Before that, it was called by the technical_definition_erp_system_change class
      this.sourceErpSystemChange(selectedErpSystem)
      this.showBrowseDataSystemLink(selectedErpSystem)
    }

    if (this.props.browseSchema) {
      // Before that, it was called by the term_erp_system_id_select class
      this.showBrowseSystemLink(selectedErpSystem)
      this.destroyRelationObjects(selectedErpSystem)
      this.changeDescription(selectedErpSystem)
    }
  };

  changeDescription = (selectedErpSystem) => {
    const descriptionSelector = this.getTechnicalDefinition().find('.term_erp_system_id_description')
    let description = selectedErpSystem?.description || ''
    description = description.replace('<ul>',"<ul class='bullets'>")

    descriptionSelector.html(description);
  }

  showBrowseSystemLink = (selectedErpSystem) => {
    const technical_definition = this.getTechnicalDefinition();
    const browseSchemaButton = technical_definition.find('.data-model-schema');

    if (selectedErpSystem) {
      browseSchemaButton.show();
      if (selectedErpSystem.schemaExists){
        browseSchemaButton.removeClass("disabled");
        browseSchemaButton.removeAttr("title");
      } else {
        browseSchemaButton.addClass("disabled");
        browseSchemaButton.attr("title","There are no data model objects to browse for the selected data system");
      }
    } else {
      browseSchemaButton.hide();
    }

    const erpSystemId = selectedErpSystem ? selectedErpSystem.value : '';
    const schemaBrowserErpSystemSelector = $j('#schema-browser__erp-system-select-' + erpSystemId)
    if (schemaBrowserErpSystemSelector.length > 0) {
      technical_definition.find('.data-model-schema').click();
    }
  }

  destroyRelationObjects = (selectedErpSystem) => {
    const technical_definition = this.getTechnicalDefinition();

    const destroyObjects = technical_definition.find('.related-data-model-attr').find('span[id*="technical-relation-object"]');
    $j.each(destroyObjects, function(index, data_object) {
      $j(data_object).find('.destroy-flag').val(true);
      data_object.hide();
    })

    var changeTechRel = technical_definition.find('.change_technical_relationship')
    if (changeTechRel.length > 0) {
      const erpSystemId = selectedErpSystem ? selectedErpSystem.value : ''
      changeTechRel[0].href +=  '&erp_system_id=' + erpSystemId

      changeTechRel.trigger('click');
    }
  }

  sourceErpSystemChange = (selectedErpSystem) => {
    const systemName = selectedErpSystem ? selectedErpSystem.label : ''
    const form = this.getTechnicalDefinition();
    const schemaButton = form.find('.data-model-schema-term-version')[0];
    form.find('.DMC-source-erp-system').html('' +
      '<label>' +
      '<li>' + systemName +
      '<a class="data-model-schema data-model-schema-term-version" href=' + schemaButton.href + '>Browse</a>' +
      '</li>' +
      '</label>'
    )
    const changeTechRel = form.find('.change_technical_relationship')
    if (changeTechRel.length > 0) {
      const erpSystemId = selectedErpSystem ? selectedErpSystem.value : '';
      changeTechRel[0].href +=  '&erp_system_id=' + erpSystemId;
      changeTechRel.trigger('click');
    }
  }

  showBrowseDataSystemLink = (selectedErpSystem) => {
    const technical_definition = this.getTechnicalDefinition();
    const browse_schema_button = technical_definition.find('.browse-data-system-link');
    if (selectedErpSystem) {
      browse_schema_button.show();
      const real_button = browse_schema_button.find('.data-model-schema-term-version');
      if (selectedErpSystem.schemaExists){
        real_button.removeClass("disabled");
        real_button.removeAttr("title");
      } else {
        real_button.addClass("disabled");
        real_button.attr("title","There are no data model objects to browse for the selected data system");
      }
    } else {
      browse_schema_button.hide();
    }
  }

  getTechnicalDefinition = () => {
    return $j(`input[name="${this.props.fieldName}"]`).closest('.technical_definition');
  };

  render() {
    const { selectedErpSystem } = this.state;
    const { hiddenFieldClass } = this.props;

    const optionsForDataSystemsSelect = this.props.erpSystems.map((erpSystem) => {
      if (erpSystem.groupFlag) {
        const header = {
          label: erpSystem.name,
          value: '',
          isHeader: true,
          isDisabled: true,
          schemaExists: false,
          description: erpSystem.systemType,
          systemLanguage: erpSystem.systemLanguage
        };

        const systems = erpSystem.childSystems.filter(s => s.hideFromDropdown == false).map(system => ({
          value: system.id,
          label: system.name,
          schemaExists: system.schemaExists,
          description: system.systemType,
          systemLanguage: erpSystem.systemLanguage
        }));
        return [header].concat(systems);
      }

      return {
        value: erpSystem.id,
        label: erpSystem.name,
        schemaExists: erpSystem.schemaExists,
        description: erpSystem.systemType,
        systemLanguage: erpSystem.systemLanguage
      };
    }).reduce((firstEl, secondEl) => firstEl.concat(secondEl), []);

    return (
      <div className="">
        <div className="virtualized-custom-wrap">
          <div className="virtualized-custom-style">
            <OptimizedSelect
              options={optionsForDataSystemsSelect}
              value={selectedErpSystem}
              onChange={selectedErpSystem => this.updateErpSystem(selectedErpSystem)}
              isClearable
            />
          </div>
        </div>
        <input
          type="hidden"
          className={`item-value term_erp_system_id_select ${hiddenFieldClass || ''}`}
          name={this.props.fieldName}
          value={selectedErpSystem ? selectedErpSystem.value : ''}
          data-schema-exists={selectedErpSystem ? selectedErpSystem.schemaExists : false}
        />
      </div>
    );
  }
}

export default DataSystemSelector;
