import React, { useState, useEffect } from 'react';

const CodeArea = (props) => {
  const { selectedLanguage, inputFieldName, queryFieldValue } = props;

  const [value, setValue] = useState(queryFieldValue || '');

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    Prism.highlightAll();
  }, [value, selectedLanguage]);

  const escapeHtmlTags = inputString => inputString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

  const handleInput = (e) => {
    const inputValue = e.currentTarget.innerText;

    setValue(escapeHtmlTags(inputValue));
  };

  const languageForClassName = (selectedLanguage) => {
    const languageForms = {
      other: '',
      'c#': 'csharp',
      'c++': 'cpp',
      'pl/sql': 'plsql',
      'plain text': ''
    };

    const formattedLanguage = languageForms[selectedLanguage.toLowerCase()];

    if (formattedLanguage) {
      return formattedLanguage;
    }

    return selectedLanguage.toLowerCase();
  };

  const textAreaClassNames = () => {
    const language = selectedLanguage.value || '';

    return `language-${languageForClassName(language)} technical-droppable functional-definition-output-mono technical-info-field-to-check`;
  };

  return (
    <div className="code-area-input-block">
      <pre style={{ padding: '1px' }}>
        <code
          onBlur={handleInput}
          className={textAreaClassNames()}
          contentEditable="plaintext-only"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </pre>
      <textarea
        hidden
        value={value}
        type="text"
        name={inputFieldName}
      />
    </div>
  );
};

export default CodeArea;
