import DMCNewSource from './DMCNewSource';
import DMCOrdering from './DMCOrdering';
import LineageTooltip from './LineageTooltip';
import { dataModelObjectName } from './shared/LineageHelper';

class LineageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.repaintLineage = this.repaintLineage.bind(this);
  }

  componentDidMount() {
    this.repaintLineage();
  }

  shouldComponentUpdate(nextProps) {
    return ((JSON.stringify(this.props.lineage) !== JSON.stringify(nextProps.lineage)) || (this.props.editable !== nextProps.editable));
  }

  componentDidUpdate() {
    this.repaintLineage();
  }

  repaintLineage() {
    const { plusImageSrc, plusImageHoverSrc, lineage, editable } = this.props;
    const { container } = this;
    const sourceDiv = this.sourceColumn;
    const sourceDivWidth = sourceDiv.offsetWidth;
    const linioDiv = this.linioColumn;
    const linioDivWidth = linioDiv.offsetWidth;
    const targetDiv = this.targetColumn;
    const targetDivWidth = targetDiv.offsetWidth;

    const data = lineage.treeJson;
    if (!data) return;

    const fullWidth = editable ? container.offsetWidth * 0.99 : container.offsetWidth;
    const rectWidth = 100;
    const largeRectWidth = 213;
    const rectHeight = 30;
    const padding = 6;

    const linesColor = '#444444';
    const linesWidth = 1.5;
    let temp_targetErpSystemId = null;
    if (this.props.targetErpSystem)
      temp_targetErpSystemId = this.props.targetErpSystem.id;

    const targetErpSystemId = temp_targetErpSystemId;
    const sourceErpSystemId = this.props.lineage.treeJson.erpSystemId;

    const getTreeHeight = function (node) {
      let maxChildHeight = 0;
      if (node.children && node.children.length > 0)
        maxChildHeight = Math.max.apply(null, node.children.map(child => getTreeHeight(child)));

      return maxChildHeight + 1;
    };

    const getSourcesCount = function (node) {
      let sourcesCount = 0;
      if (node.children) {
        node.children.forEach((child) => {
          sourcesCount += getSourcesCount(child);
        });
      } else
        sourcesCount = 1;

      node.sourcesCount = sourcesCount;
      return sourcesCount;
    };

    getSourcesCount(data);

    let treeHeight = getTreeHeight(data);
    if (data.sourcesCount === 0) treeHeight++;
    let linioContainerWidth;
    if (treeHeight < 5)
      linioContainerWidth = 500;
    else {
      container.style['overflow-x'] = 'scroll';
      linioContainerWidth = 150 * (treeHeight - 2);
    }
    const fullImageWidth = sourceDivWidth + linioContainerWidth + targetDivWidth;

    const horizontalSpaceBetweenColumns = (fullImageWidth / treeHeight - rectWidth) / 4;
    const height = data.sourcesCount * (rectHeight + 35) || rectHeight + 35;

    d3.select(sourceDiv).select('svg').remove();
    d3.select(linioDiv).select('svg').remove();
    d3.select(targetDiv).select('svg').remove();

    if (data.sourcesCount === 0)
      sourceDiv.style.height = '16px';
    else
      sourceDiv.style.height = '';

    const sourceContainer = d3.select(sourceDiv).append('svg')
      .attr('width', sourceDivWidth)
      .attr('height', (data.sourcesCount > 0 ? height : 1));

    const linioContainer = d3.select(linioDiv).append('svg')
      .attr('width', linioContainerWidth)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${-sourceDivWidth} , 0)`);

    const targetContainer = d3.select(targetDiv).append('svg')
      .attr('width', targetDivWidth)
      .attr('height', height)
      .attr('class', this.props.onlyLinio ? 'hide' : '')
      .append('g')
      .attr('transform', `translate(${-(sourceDivWidth + linioContainerWidth)} , 0)`);

    const tooltip = new LineageTooltip(container);

    const calculateNodesPositions = function (node, nodeLevel, yBegin, yEnd, root) {
      let x = 0;
      if (nodeLevel === 0)
        x = (fullWidth / treeHeight) * nodeLevel - largeRectWidth / 2 - horizontalSpaceBetweenColumns;
      else if (root)
        x = fullImageWidth - padding - largeRectWidth / 2;
      else
        x = 225 + ((fullImageWidth - 225) / treeHeight) * (nodeLevel - 1) - rectWidth / 2;

      const y = (yBegin + yEnd) / 2;

      if (!node.children)
        x = padding + largeRectWidth / 2;

      node.x = x;
      node.y = y;

      if (node.children) {
        let y1 = yBegin;
        node.children.forEach((child) => {
          const part = (node.sourcesCount === 0) ? 1 : (child.sourcesCount / node.sourcesCount);
          const y2 = y1 + (yEnd - yBegin) * part;
          calculateNodesPositions(child, nodeLevel - 1, y1, y2);
          y1 = y2;
        });
      }

      if (node.type === 'LinioSource')
        node.canBeDeleted = node.canBeDeleted || data.sourcesCount === 1;
    };

    calculateNodesPositions(data, treeHeight, 0, height, true);

    const getTooltipContentForLineageTarget = (node) => {
      let html = '';
      const { reportItem } = node;
      const { term, dataModelObject, objectLabel } = reportItem;
      if (!(objectLabel && term && objectLabel === term.name) && !(objectLabel && dataModelObject && objectLabel === dataModelObject.name))
        html += `<b>Label: ${objectLabel}</b><br>`;

      if (term)
        html += `<b>Definition: ${term.name}</b><br>`;

      if (dataModelObject) {
        html += `<b>Data Model Object: ${dataModelObject.parentObject.dataSchemaVersion.name}.`;
        html += `${dataModelObject.parentObject.name}.`;
        html += `${dataModelObject.name}</b><br>`;
      }
      return html;
    };

    const getTooltipContentForLinioSource = (node) => {
      let html = '';
      const { termName, objectLabel, relatedObject } = node;
      if (!(objectLabel && termName && objectLabel === termName) && !(objectLabel && relatedObject && objectLabel === relatedObject.name))
        html += `<b>Label: ${objectLabel}</b><br>`;

      if (termName)
        html += `<b>Definition: ${termName}</b><br>`;

      if (relatedObject) {
        html += `<b>Data Model Object: ${relatedObject.parentObject.dataSchemaVersion.name}.`;
        html += `${relatedObject.parentObject.name}.`;
        html += `${relatedObject.name}</b><br>`;
      }
      return html;
    };

    const getTooltipContent = (node) => {
      let html;
      if (node.type === 'LineageTarget')
        html = getTooltipContentForLineageTarget(node);
      else if (node.type === 'LinioSource')
        html = getTooltipContentForLinioSource(node);
      else
        html = `<b>${node.name}</b>`;

      if (node.note)
        html += `<br>Note: ${node.note}`;

      return html;
    };

    const getTextLinesForLinioSource = (node) => {
      const { type } = node;
      if (type !== 'LinioSource') return [];
      const lines = [];
      const { objectLabel } = node;
      const objectType = node.relatedObjectType || '';

      if (node.termId) {
        const term = this.props.terms.find(trm => (trm.id === node.termId));
        if (term)
          lines.push({ name: term.name, linkTo: node.linkToTerm } || { name: '' });
      }

      if (node.relatedObject && objectType.startsWith('DataModel'))
        lines.push({ name: dataModelObjectName(node.relatedObject), linkTo: node.linkToRelatedObject } || { name: '' });

      if (objectLabel && !lines.map(x => x.name).includes(objectLabel) && objectLabel !== node.relatedObject?.name)
        lines.unshift({ name: node.name });

      return lines;
    };

    const isDataModel = (reportItem) => {
      const { technicalRelationship } = reportItem;

      if (reportItem.dataModelObjectType) {
        return reportItem.dataModelObjectType.startsWith('DataModel')
      }

      return technicalRelationship.objectType.startsWith('DataModel')
    };

    const getTextLinesForLineageTarget = (node) => {
      const { reportItem } = node;
      const { term, dataModelObject } = reportItem;
      const { objectLabel } = reportItem;
      const lines = [];
      if (term)
        lines.push({ name: term.name, linkTo: term.defaultShowLink } || { name: '' });

      if (dataModelObject && isDataModel(reportItem))
        lines.push({ name: dataModelObjectName(dataModelObject), linkTo: dataModelObject.linkToObject } || { name: '' });

      if (objectLabel && !lines.map(x => x.name).includes(objectLabel) && objectLabel !== dataModelObject?.name)
        lines.unshift({ name: node.name } || { name: reportItem.objectLabel });

      return lines;
    };

    const getTextLines = (node, sourceErpSystemId) => {
      const { type } = node;
      switch (type) {
        case 'LinioProcessing':
          return [{ name: node.name } || ''];
        case 'LinioSource':
          return getTextLinesForLinioSource(node, sourceErpSystemId);
        case 'LineageTarget':
          return getTextLinesForLineageTarget(node);
        default:
          return [];
      }
    };

    const drawTextContent = (container, node, width, sourceErpSystemId, targetErpSystemId, index) => {
      const lines = getTextLines(node, sourceErpSystemId, targetErpSystemId);
      let classes = 'dmc-lineage__text';
      let linkTag = null;

      lines.forEach((line, i) => {
        let text = line.name;
        if (node.type === 'LinioProcessing') {
          const maxLength = 8;
          const postfix = text.length > maxLength ? '...' : '';
          text = `${text.substring(0, maxLength)}${postfix}`
        }
        if (line.linkTo) {
          linkTag = container.append('svg:a').attr('xlink:href', line.linkTo);
          classes += ' dmc-lineage__linkable';
        }
        const newContainer = linkTag || container;
        newContainer.append('text')
          .attr('x', node.x - width / 2 + 8)
          .attr('y', node.y - (lines.length - 1) * 15 / 2 + i * 15)
          .attr('dy', '0.33em')
          .attr('dx', '1em')
          .attr('class', classes)
          .attr('style', `clip-path: url(#clipPath-${node.type}-${index}-${node.id});`)
          .text(text)
          .on('mouseover', () => { tooltip.show(getTooltipContent(node)); })
          .on('mouseout', () => { tooltip.hide(); })
          .on('click', () => {
            if (line.linkTo)
              window.location.href = line.linkTo;
          });
      });
    };

    const drawNode = (node, sourceErpSystemId, targetErpSystemId, index) => {
      if (!node) return;
      let container = sourceContainer;
      if (node.type === 'LinioProcessing')
        container = linioContainer;
      else if (node.type === 'LineageTarget')
        container = targetContainer;

      const width = (node.type === 'LinioProcessing') ? rectWidth : largeRectWidth;
      const widthReducer = node.type === 'LinioProcessing' ? 0 : 20;
      let height = rectHeight;
      height += (getTextLines(node, sourceErpSystemId, targetErpSystemId).length - 1) * 15;

      container.append('rect').attr('x', node.x - width / 2)
        .attr('y', node.y - height / 2)
        .attr('width', width)
        .attr('height', height)
        .style('fill', '#ffffff')
        .style('stroke', linesColor)
        .style('stroke-width', linesWidth)
        .on('mouseover', () => { tooltip.show(getTooltipContent(node)); })
        .on('mouseout', () => { tooltip.hide(); });
      
      container.append('clipPath')
        .attr('id', `clipPath-${node.type}-${index}-${node.id}`)
        .append('rect')
        .attr('x', node.x - width / 2)
        .attr('y', node.y - height / 2)
        .attr('width', width - widthReducer)
        .attr('height', height)

      drawTextContent(container, node, width, sourceErpSystemId, targetErpSystemId, index);

      if (editable && !node.fromTechnicalDefinition) {
        if (['LinioProcessing', 'LinioSource'].includes(node.type)) {
          drawDeleteIcon(node, container, width, height);
          drawEditIcon(node, container, width, height, true);
        } else
          drawEditIcon(node, container, width, height, false);
      }

      if (['LinioSource', 'LineageTarget'].includes(node.type))
        drawNodeTypeIconForSourceOrTarget(node, container, width);
      else
        drawNodeTypeIcon(node, container, width);
    };

    const getNodeImage = (node) => {
      const {
        processingImageSrc,
        termImageSrc,
        databaseImageSrc,
        textImageSrc
      } = this.props;

      const objectType = node.relatedObjectType || '';

      let image = processingImageSrc;
      if (node.type !== 'LinioProcessing') {
        if (objectType === 'Term')
          image = termImageSrc;
        else if (objectType.startsWith('DataModel'))
          image = databaseImageSrc;
        else
          image = textImageSrc;
      }

      return image;
    };

    const getImagesForSource = (node) => {
      const {
        termImageSrc,
        databaseImageSrc,
        textImageSrc
      } = this.props;
      const { objectLabel } = node;
      const objectType = node.relatedObjectType || '';

      const images = [];
      const imageTypes = [];
      const lines = [];

      if (node.termId) {
        lines.push(node.termName);
        images.push(termImageSrc);
        imageTypes.push('Term');
      }

      if (node.relatedObject && objectType.startsWith('DataModel')) {
        lines.push(node.relatedObject.name);
        images.push(databaseImageSrc);
        imageTypes.push('DataModel');
      }

      if (objectLabel && !lines.includes(objectLabel)) {
        images.unshift(textImageSrc);
        imageTypes.unshift('Label');
      }

      return [images, imageTypes];
    };

    const getImagesForTarget = (node) => {
      const {
        termImageSrc,
        databaseImageSrc,
        textImageSrc
      } = this.props;

      const { reportItem } = node;
      const { term, dataModelObject, objectLabel } = reportItem;

      const images = [];
      const imageTypes = [];
      const lines = [];

      if (term) {
        lines.push(term.name);
        images.push(termImageSrc);
        imageTypes.push('Term');
      }

      if (dataModelObject && isDataModel(reportItem)) {
        lines.push(dataModelObject.name);
        images.push(databaseImageSrc);
        imageTypes.push('DataModel');
      }

      if (objectLabel && !lines.includes(objectLabel)) {
        images.unshift(textImageSrc);
        imageTypes.unshift('Label');
      }

      return [images, imageTypes];
    };

    const getImagesForSourceOrTarget = (node) => {
      const { type } = node;
      switch (type) {
        case 'LinioSource':
          return getImagesForSource(node);
        case 'LineageTarget':
          return getImagesForTarget(node);
        default:
          return [[], []];
      }
    };

    const drawNodeTypeIcon = (node, container, width) => {
      const imageSize = 15;
      const margin = 5;
      const image = getNodeImage(node);

      container.append('image')
        .attr('x', node.x - width / 2 + margin)
        .attr('y', node.y - imageSize / 2)
        .attr('width', imageSize)
        .attr('height', imageSize)
        .attr('xlink:href', image)
        .on('mouseover', () => { tooltip.show(getTooltipContent(node)); })
        .on('mouseout', () => { tooltip.hide(); });
    };

    const getPaddingSize = (imageSize, imagesCount, imageTypes, counter) => {
      let paddingY = 0;
      switch (imagesCount) {
        case 1:
          paddingY = imageSize / 2;
          break;
        case 2:
          paddingY = 15 - (counter * 15);
          break;
        case 3:
          paddingY = 23 - (counter * 15);
          break;
      }
      return paddingY;
    };

    const drawNodeTypeIconForSourceOrTarget = (node, container, width) => {
      const imageSize = 15;
      const margin = 5;
      const [images, imageTypes] = getImagesForSourceOrTarget(node);
      const imagesCount = images.length;

      images.forEach((image, i) => {
        container.append('image')
          .attr('x', node.x - width / 2 + margin)
          .attr('y', node.y - getPaddingSize(imageSize, imagesCount, imageTypes, i))
          .attr('width', imageSize)
          .attr('height', imageSize)
          .attr('xlink:href', image)
          .on('mouseover', () => {
            tooltip.show(getTooltipContent(node));
          })
          .on('mouseout', () => {
            tooltip.hide();
          });
      });
    };

    const drawEditIcon = (node, container, width, height, low = true) => {
      const imageSize = 14;
      const margin = 1;
      const verticalPosition = low ? imageSize : 0;

      const editIconSrc = this.props.editImageSrc;

      container.append('image')
        .attr('x', node.x + width / 2 - imageSize - margin)
        .attr('y', node.y - height / 2 + margin + verticalPosition)
        .attr('width', imageSize)
        .attr('height', imageSize)
        .attr('xlink:href', editIconSrc)
        .attr('class', 'dmc-lineage__control')
        .attr('alt', 'Edit lineage item')
        .on('mouseover', () => {
          d3.select(this.targetColumn).attr('xlink:href', editIconSrc);
        })
        .on('mouseout', () => {
          d3.select(this.targetColumn).attr('xlink:href', editIconSrc);
        })
        .on('click', () => {
          const { onEditNode, lineage } = this.props;
          onEditNode(node, lineage);
        });
    };

    const drawDeleteIcon = (node, container, width, height) => {
      const imageSize = 14;
      const margin = 1;

      let deleteIconSrc;
      if (node.canBeDeleted)
        deleteIconSrc = this.props.deleteImageSrc;
      else
        deleteIconSrc = this.props.deleteDisabledImageSrc;

      container.append('image')
        .attr('x', node.x + width / 2 - imageSize - margin)
        .attr('y', node.y - height / 2 + margin)
        .attr('width', imageSize)
        .attr('height', imageSize)
        .attr('xlink:href', deleteIconSrc)
        .attr('class', 'dmc-lineage__control')
        .attr('alt', 'Delete lineage item')
        .on('mouseover', () => {
          d3.select(this).attr('xlink:href', deleteIconSrc);
        })
        .on('mouseout', () => {
          d3.select(this).attr('xlink:href', deleteIconSrc);
        })
        .on('click', () => {
          const { onDelete, lineage } = this.props;
          onDelete(node, lineage);
        });
    };

    const drawLine = function (x1, y1, x2, y2, container, dashed) {
      const line = container.append('line')
        .style('stroke', linesColor)
        .style('stroke-width', linesWidth)
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x2)
        .attr('y2', y2);
      if (dashed) {
        line.style('stroke-dasharray', ('4, 4'))
          .style('stroke-width', 1);
      }
    };

    const drawEdge = function (node1, node2, dashed) {
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      const containers = isIE11 ? [linioContainer] : [sourceContainer, linioContainer, targetContainer];

      const firstNodeWidth = (node1.type === 'LinioSource') ? largeRectWidth : rectWidth;
      const secondNodeWidth = (node2.type === 'LineageTarget') ? largeRectWidth : rectWidth;

      const x1 = node1.x + firstNodeWidth / 2;
      const y1 = node1.y;
      const x2 = node2.x - secondNodeWidth / 2;
      let y2 = node2.y;

      if (y1 !== y2)
        y2 = y1 < y2 ? y2 - 5 : y2 + 5;

      containers.forEach((container) => {
        drawLine(x1, y1, x2 - horizontalSpaceBetweenColumns, y1, container, dashed);
        drawLine(x2 - horizontalSpaceBetweenColumns, y1, x2 - horizontalSpaceBetweenColumns, y2, container, dashed);
        drawLine(x2 - horizontalSpaceBetweenColumns, y2, x2, y2, container, dashed);
      });
    };

    const drawPlus = (node, parentNode) => {
      let container = sourceContainer;
      if (node.type === 'LinioProcessing' || node.type === 'LinioSource')
        container = linioContainer;

      const nodeWidth = node.type === 'LinioSource' ? largeRectWidth : rectWidth;

      const margin = node.children ? horizontalSpaceBetweenColumns / 2 : horizontalSpaceBetweenColumns * 1.5;
      const plusSize = 15;
      if (lineage.id) {
        container.append('image')
          .attr('x', node.x + nodeWidth / 2 + margin - plusSize / 2)
          .attr('y', node.y - plusSize / 2)
          .attr('width', plusSize)
          .attr('height', plusSize)
          .attr('xlink:href', plusImageSrc)
          .attr('class', 'dmc-lineage__control')
          .attr('alt', 'Add a processing step icon')
          .on('mouseover', function () {
            d3.select(this).attr('xlink:href', plusImageHoverSrc);
          })
          .on('mouseout', function () {
            d3.select(this).attr('xlink:href', plusImageSrc);
          })
          .on('click', () => {
            d3.event.stopPropagation();
            const { openNewProcessingStepForm, lineage } = this.props;
            openNewProcessingStepForm((!node.fromTechnicalDefinition && node), parentNode, lineage);
          });
      }
    };

    const drawProcessingWithoutSourceControl = (node) => {
      if (!node.children || node.children.length > 0) return;
      const fakeSourceNode = {
        type: 'LinioSource',
        x: padding + largeRectWidth / 2,
        y: node.y
      };
      drawEdge(fakeSourceNode, node, true);
      editable && drawPlus(fakeSourceNode, node);
    };

    const drawSubtree = function (node, parentNode, index) {
      drawNode(node, sourceErpSystemId, targetErpSystemId, index);

      if (parentNode) {
        drawEdge(node, parentNode);
        editable && !parentNode.fromTechnicalDefinition && drawPlus(node, parentNode);
      }

      drawProcessingWithoutSourceControl(node);

      if (node.children)
        node.children.forEach((child, index) => drawSubtree(child, node, index));
    };

    const colorGeneralControl = function(container) {
      const sourceWidth = sourceDiv.offsetWidth;
      const linioWidth = linioDiv.offsetWidth;
      const controlDiv = container.getElementsByClassName('dmc-lineage__control-panel');
      if (controlDiv[0])
        controlDiv[0].style.background = `linear-gradient(to right, #f0f0f0 0%, #f0f0f0 ${sourceWidth}px, #dbf1ff ${sourceWidth}px, #dbf1ff ${sourceWidth + linioWidth}px, #f0f0f0 ${sourceWidth + linioWidth}px, #f0f0f0 100%)`;
    };

    drawSubtree(data);
    colorGeneralControl(container);
  }

  findLastChildProcessing(node) {
    if (!node.children) return null;
    const lastChildren = node.children[node.children.length - 1];
    if (lastChildren && lastChildren.type === 'LinioProcessing')
      return lastChildren;

    return null;
  }

  findProcessingForNewSource(node) {
    if (!node || !node.children) return null;
    let lastChildProcessing = this.findLastChildProcessing(node);
    while (lastChildProcessing && this.findLastChildProcessing(lastChildProcessing))
      lastChildProcessing = this.findLastChildProcessing(lastChildProcessing);

    return lastChildProcessing;
  }

  getSourceErpSystem(erpSystemId) {
    const { sourceErpSystems } = this.props;
    if (!erpSystemId || !sourceErpSystems) return null;
    return sourceErpSystems.find(erpSystem => (erpSystem.id === erpSystemId));
  }

  render() {
    const { lineage, editable } = this.props;
    const { hasTechnicalDefinitionLineage } = lineage.treeJson;
    const target = lineage.treeJson;
    const { erpSystemId } = lineage ? lineage.treeJson : null;
    const firstProcessing = this.findProcessingForNewSource(target);
    const targetObject = firstProcessing || target;
    const canAddSource = firstProcessing || (target.children || []).length === 0;
    const sourceParams = {
      targetObjectId: targetObject.id,
      targetObjectType: targetObject.type,
      lineageId: lineage.id
    };
    const sourceErpSystem = this.getSourceErpSystem(erpSystemId);
    let sourceErpSystemName = null;
    let sourceErpSystemDescription = null;
    if (sourceErpSystem) {
      sourceErpSystemName = sourceErpSystem.name;
      sourceErpSystemDescription = sourceErpSystem.system_type || sourceErpSystem.systemType;
    }
    return (
      <div
        className="dmc-lineage"
        ref={el => this.container = el}
        data-id={lineage.id}
      >
        { editable &&
        <div
          className="dmc__sortable-handle"
        />
        }

        <div style={{ display: 'table-cell' }}>
          <div className={`dmc-lineage__source-column ${hasTechnicalDefinitionLineage ? 'dmc-lineage__tech_def_linio_source' : ''}`}>
            <div ref={el => this.sourceColumn = el} />
            { editable && !hasTechnicalDefinitionLineage &&
            <div className="dmc-lineage__new-source">
              <DMCNewSource
                sourceParams={sourceParams}
                canAddSource={canAddSource}
                sourceErpSystemName={sourceErpSystemName}
                sourceErpSystemDescription={sourceErpSystemDescription}
                {...this.props}
              />
            </div>
            }
            {
              (!editable || hasTechnicalDefinitionLineage) && sourceErpSystemName &&
              <div className="dmc-source__system_name" style={{ margin: 'auto' }} title={sourceErpSystemDescription}>
                {sourceErpSystemName}
              </div>
            }
          </div>

          <div
            className="dmc-lineage__linio-column"
            ref={el => this.linioColumn = el}
          />

          <div
            className="dmc-lineage__target-column"
            ref={el => this.targetColumn = el}
          />

          { editable &&
          <div className="dmc-lineage__control-panel">
            <div className="dmc-lineage__general-control">
              <DMCOrdering
                lineage={lineage}
                {...this.props}
              />
              { lineage.id &&
                <div className="dmc-lineage__delete-btn">
                  <a
                    href="#"
                    onClick={e => this.props.onLineageDelete(e, lineage)}
                  >
                    Delete
                  </a>
                </div>
              }
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default LineageComponent;
