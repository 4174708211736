import { render, fireEvent } from '@testing-library/react';
import SimpleSelector from './SimpleSelector';

describe('<SimpleSelector />', () => {
  it('renders dropdown indicatior', async () => {
    const component = buildDocmponent();
    const dropdownIndicator = await component.findAllByTitle('Show all options');
    expect(dropdownIndicator.length).toBe(1);
  });

  it('renders selector', async () => {
    const component = buildDocmponent();
    const selector = await component.findAllByRole('combobox');
    expect(selector.length).toBe(1);
  });

  it('allow to select options', async () => {
    const component = buildDocmponent();
    const { getByText } = component;
    const selector = await component.findAllByRole('combobox');

    fireEvent.change(selector[0], { target: { value: 'value1', label: 'value1' } });

    expect(getByText('value1')).toBeInTheDocument();
  });

  it('shows selected value by default', async () => {
    const { getByText } = buildDocmponent();

    expect(getByText('initial value')).toBeInTheDocument();
  });
});

function buildDocmponent() {
  const props = getProps();
  return render(<SimpleSelector{...props} />);
}

function getProps() {
  return {
    value: { value: 'init value', label: 'initial value' },
    inputFieldName: 'test',
    options: selectorOptions(),
    onChange: () => {}
  };
}

function selectorOptions() {
  return [
    { value: 'value1', label: 'value1' },
    { value: 'value2', label: 'value2' },
    { value: 'value3', label: 'value3' }
  ];
}
